<template>
  <UserDetailItemEditCard confirmation-message="Statuut werd succesvol gewijzigd"
                      title="Bewerk statuut"
                      :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                      update-store-action="usersModule/updateStatute"
                      :update-store-action-payload="{statuteDTO: this.statuteDTO, userId: this.userId}"
                      :userId="userId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" sm="6">
          <FetchOptionsSelect action="usersModule/fetchStatutes" type="text" v-model="statuteDTO.statute" label="Statuut" required :combobox="true"></FetchOptionsSelect>
        </v-col>
      </v-row>
    </template>
  </UserDetailItemEditCard>
</template>

<script>

import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";

export default {
  name: "UserDetailHeaderEditStatute.vue",
  components: {
    UserDetailItemEditCard,
    FetchOptionsSelect
  },
  props: {
    userId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      routeNames: routeNames,
      statuteDTO: { },
      promises: [
        this.$store.dispatch('usersModule/fetchStatute', this.userId)
          .then((result) => this.statuteDTO = result)
      ]
    }
  },
  computed: {
    getStatutes() {
      return this.$store.state.usersModule.statutes
          ? this.$store.state.usersModule.statutes
          : []
    },
  }
}
</script>
